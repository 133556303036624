import React from 'react';

import worldImg from 'assets/imgs/worldmap-bg.png';
import { Button, Checkbox, Form, Input, message } from 'antd';

import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { useNavigate, Link } from 'react-router-dom';
import AdminApi from 'apis/admin';
import styles from './Login.module.scss';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { run: runSubmit, loading: runSubmiting } = useRequest(AdminApi.login, {
    manual: true,
    onSuccess(data) {
      message.success(t('Res.Welcome') + data.tokenData.userId);
      navigate('/admin/user');
    },
    onError(e) {},
  });

  const onFinish = (values) => {
    runSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <main
      className={styles.main}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header />
      <div className={styles['login-box']}>
        <div className={styles['title-box']}>
          <h2>{t('Admin')}</h2>
        </div>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label={t('Username')}
            name='username'
            rules={[
              { required: true, message: t('Please input your username!') },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Password')}
            name='password'
            rules={[
              { required: true, message: t('Please input your password!') },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div className={styles['submit-btn-box']}>
            <Button
              loading={runSubmiting}
              type='primary'
              block
              htmlType='submit'
            >
              {t('Login')}
            </Button>
          </div>
        </Form>
      </div>
    </main>
  );
}

export default Login;

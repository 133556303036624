const { useState } = require('react');

function useAuth(isAdmin) {
  const [token, setToken] = useState(() => {
    const storeToken = window.localStorage.getItem(
      isAdmin ? 'adminToken' : 'token',
    );
    if (storeToken) {
      return storeToken;
    }
    return null;
  });

  const [tokenData, setTokenData] = useState(() => {
    const storeTokenData = window.localStorage.getItem(
      isAdmin ? 'adminTokenData' : 'tokenData',
    );
    if (storeTokenData) {
      try {
        return JSON.parse(storeTokenData);
      } catch (error) {
        return null;
      }
    }
    return null;
  });

  return {
    token,
    tokenData,
  };
}

export default useAuth;

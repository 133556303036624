import axios from 'axios';
import { message } from 'antd';
import i18n from '../i18n';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/api'
      : 'http://127.0.0.1:7001/api',
});

export default function fetch(config) {
  if (!config.headers) {
    config.headers = {};
  }
  if (config.fullUrl) {
    return axios({
      ...config,
      url: config.fullUrl,
    });
  }
  config.headers.authorization = window.localStorage.getItem(
    config.isAdmin ? 'adminToken' : 'token',
  );
  return instance(config)
    .then((res) => res.data)
    .catch((e) => {
      message.error(i18n.t(`Res.${e.response.data}`));
      if (e.response.data === 'Unauthorized') {
        window.localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }
      return Promise.reject(e);
    });
}

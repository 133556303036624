import { Button, Input } from 'antd';
import { useState } from 'react';
import styles from './Search.module.scss';

function SearchBox({ onSearch }) {
  const [searchVal, setSearchVal] = useState('');
  const clickSearch = () => {
    if (onSearch) {
      return onSearch(searchVal);
    }
    return false;
  };
  return (
    <div className={styles.container}>
      <div className={styles['search-row']}>
        <div className={styles['search-input']}>
          <Input
            allowClear
            width='100%'
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </div>
        <div className={styles['search-btn']}>
          <Button onClick={() => clickSearch()} type='primary'>
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SearchBox;
